<template>
  <b-card-code>
    <div v-if="$can('create', 'Subject')" class="custom-search d-flex mb-2">
      <router-link :to="{ name: 'add-subject' }">
        <b-button variant="outline-primary">
          {{ $t('Add New') }}
        </b-button>
      </router-link>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <b-col md="3">
        <b-card-text>
          <span>Select Class </span>
        </b-card-text>
        <validation-provider #default="{ errors }" name="class" rules="">
          <select name="classes_id" v-model="classes_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="form-control">
            <option :value="null">Select One</option>
            <option v-for="(cls, key) in classes" :value="cls.id" :key="key">
              {{ cls.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <!-- <b-col md="3">
        <b-card-text>
          <span>Select Version</span>
        </b-card-text>
        <validation-provider #default="{ errors }" name="version" rules="">
          <select name="version_id" v-model="version_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="form-control">
            <option :value="null">Select One</option>
            <option v-for="(ver, key) in versions" :value="ver.id" :key="key">
              {{ ver.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col> -->
      <b-col md="3">
        <b-card-text>
          <span>Select Group</span>
        </b-card-text>
        <validation-provider #default="{ errors }" name="student group" rules="">
          <select name="student_group_id" v-model="student_group_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="form-control">
            <option :value="null">Select One</option>
            <option v-for="(group, key) in student_group_list" :value="group.id" :key="key">
              {{ group.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
    </div>

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx :data="getData" :columns="columns10" :filename="'filename'" :sheetname="'subjects'">
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData" :csv-title="'My_CSV'">
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>
    <!-- table -->
    <vue-good-table style="white-space: nowrap;
    text-align: center;
    vertical-align : middle;" :columns="columns" :rows="subjects" :rtl="direction" :search-options="{
      enabled: true,
      externalQuery: searchTerm
    }" :pagination-options="{
  enabled: true,
  perPage: pageLength
}">
      <template slot="table-row" slot-scope="props">
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>

        <span v-else-if="props.column.field === 'is_optional'">
          {{ (props.row.is_optional === 1) ? 'Yes' : 'No' }}
        </span>

        <span v-else-if="props.column.field === 'is_active'">
          <template>
            <b-form-checkbox v-if="$can('status change', 'Subject')" :name="'check-button' + props.row.originalIndex"
              :id="'check-button' + props.row.originalIndex" @change="statusChange(props.row.id)"
              v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
            <b-badge v-else :variant="statusVariant(props.row.is_active)">
              {{ props.row.is_active ? 'Active' : 'Deactivated' }}
            </b-badge>
          </template>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button v-if="$can('read', 'Subject')" variant="secondary" @click="viewDetails(props.row)">
                <feather-icon class="text-body " icon="EyeIcon" size="15" />
              </b-button>
              <b-button v-if="$can('update', 'Subject')" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click="showModal(props.row)" v-b-modal.modal-login variant="primary">
                <feather-icon class="text-body " icon="EditIcon" size="15" />
              </b-button>
              <b-button v-if="$can('remove', 'Subject')" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click="remove(props.row.id, props.row.originalIndex)" v-b-modal.modal-login variant="danger">
                <feather-icon class="text-body " icon="TrashIcon" size="15" />
              </b-button>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select v-model="pageLength" :options="['10', '15', '20', '50', '100', '500']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!--Edit modal-->
    <b-modal ref="my-modal" hide-footer size="lg" :title="$t('Edit Subject')">
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form method="post" ref="add_or_update_form" @submit="edit" action="javascript:void(0)">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Subject Name') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('name')" rules="required">
                <b-form-input name="name" v-model="selected_row.name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write name')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Short Name </span>
              </b-card-text>
              <b-form-group>
                <validation-provider #default="{ errors }" name="Short name" rules="required">
                  <select name="short_name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="selected_row.short_name" class="form-control">
                    <option :value="null">Select One</option>
                    <option value="HMD1">HMD1</option>
                    <option value="HMD2">HMD2</option>

                    <option value="AC1">AC1</option>
                    <option value="AC2">AC2</option>

                    <option value="ACC1">ACC1</option>
                    <option value="ACC2">ACC2</option>

                    <option value="AGRI">AGRI</option>
                    <option value="AGRI1">AGRI1</option>
                    <option value="AGRI2">AGRI2</option>

                    <option value="BANG1">BANG1</option>
                    <option value="BANG2">BANG2</option>

                    <option value="BENT1">BENT1</option>
                    <option value="BENT2">BENT2</option>

                    <option value="BGS1">BGS1</option>
                    <option value="BGS2">BGS2</option>

                    <option value="BIO1">BIO1</option>
                    <option value="BIO2">BIO2</option>

                    <option value="CE1">CE1</option>
                    <option value="CE2">CE2</option>

                    <option value="CHE1">CHE1</option>
                    <option value="CHE2">CHE2</option>

                    <option value="HR/CRS1">HR/CRS1</option>
                    <option value="HR/CRS2">HR/CRS2</option>

                    <option value="ECON1">ECON1</option>
                    <option value="ECON2">ECON2</option>

                    <option value="ENG1">ENG1</option>
                    <option value="ENG2">ENG2</option>

                    <option value="FIN1">FIN1</option>
                    <option value="FIN2">FIN2</option>

                    <option value="GM1">GM1</option>
                    <option value="GM2">GM2</option>

                    <option value="HM1">HM1</option>
                    <option value="HM2">HM2</option>

                    <option value="HSCI1">HSCI1</option>
                    <option value="HSCI2">HSCI2</option>

                    <option value="ICT1">ICT1</option>
                    <option value="ICT2">ICT2</option>

                    <option value="IME1">IME1</option>
                    <option value="IME2">IME2</option>

                    <option value="MATH">MATH</option>
                    <option value="MATH1">MATH1</option>
                    <option value="MATH2">MATH2</option>

                    <option value="MGMT1">MGMT1</option>
                    <option value="MGMT2">MGMT2</option>

                    <option value="PE1">PE1</option>
                    <option value="PE2">PE2</option>

                    <option value="PHY">PHY</option>
                    <option value="PHY1">PHY1</option>
                    <option value="PHY2">PHY2</option>

                    <option value="SCI">SCI</option>
                    <option value="SCI1">SCI1</option>
                    <option value="SCI2">SCI2</option>

                    <option value="STAT1">STAT1</option>
                    <option value="STAT2">STAT2</option>

                    <option value="WL1">WL1</option>
                    <option value="WL2">WL2</option>

                    <option value="SELFSTD1">SELFSTD1</option>
                    <option value="SELFSTD2">SELFSTD2</option>

                    <option value="TMP1">TMP1</option>
                    <option value="TMP2">TMP2</option>

                    <option value="FMD1">FMD1</option>
                    <option value="FMD2">FMD2</option>

                    <option value="LIBW1">LIBW1</option>
                    <option value="LIBW2">LIBW2</option>

                    <option value="MOETH1">MOETH1</option>
                    <option value="MOETH2">MOETH2</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>Select Group</span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="student group" rules="">
                <select name="student_group_id" v-model.number="selected_row.student_group_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">Select One</option>
                  <option v-for="(grp, key) in student_group_list" :value="grp.id" :key="key">
                    {{ grp.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Subject code') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('subject code')" rules="">
                <b-form-input name="code" v-model="selected_row.code"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :placeholder="$t('Write subject code here')"
                  class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Is Optional?') }}</span>
              </b-card-text>
              <validation-provider #default="{ errors }" name="subject is optional or not?" rules="required">
                <b-form-radio-group v-model="selected" :options="options" name="is_optional">
                </b-form-radio-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

          </b-row>
          <!-- submit button -->
          <b-col>
            <b-button variant="primary" type="submit">Submit</b-button>
          </b-col>
        </form>
      </validation-observer>
    </b-modal>

    <!-- view model -->
    <b-modal ref="information-view" hide-footer size="sm" :title="$t('Subject Details')">
      <table style="border-collapse: separate; border-spacing: 0 15px; ">
        <tr>
          <td>Subject Name</td>
          <td>: {{ selected_row.name }}</td>
        </tr>
        <tr>
          <td>Short Name</td>
          <td>: {{ selected_row.short_name }}</td>
        </tr>
        <tr>
          <td>Subject Code</td>
          <td>: {{ selected_row.code }}</td>
        </tr>
        <tr>
          <td>Class</td>
          <td>: {{ selected_row.classes ? selected_row.classes.name : '' }}</td>
        </tr>
        <tr>
          <td>Version</td>
          <td>: {{ selected_row.version ? selected_row.version.name : '' }}</td>
        </tr>
        <tr>
          <td>Student Group</td>
          <td>: {{ selected_row.student_group ? selected_row.student_group.name : '' }}</td>
        </tr>
        <tr>
          <td>Has Part ? </td>
          <td>: <b-badge :variant="statusVariant(selected_row.has_part)">
              {{ status2[selected_row.has_part] }}
            </b-badge>
          </td>
        </tr>
        <tr>
          <td>Is Optional ? </td>
          <td>: <b-badge :variant="statusVariant(selected_row.is_optional)">
              {{ status2[selected_row.is_optional] }}
            </b-badge>
          </td>
        </tr>
        <tr>
          <td>Has Mark Effect ? </td>
          <td>: <b-badge :variant="statusVariant(selected_row.has_mark_effect)">
              {{ status2[selected_row.has_mark_effect] }}
            </b-badge>
          </td>
        </tr>
        <tr>
          <td>Is Grade Subject ? </td>
          <td>: <b-badge :variant="statusVariant(selected_row.is_grade_subject)">
              {{ status2[selected_row.is_grade_subject] }}
            </b-badge>
          </td>
        </tr>
      </table>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BFormCheckbox,
  BRow, BCol, BCardText, BFormRadioGroup
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
import { mapGetters } from "vuex";
import { required } from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
export default {
  name: 'Subject',
  components: {
    BCardCode,
    VueGoodTable, BFormCheckbox,
    BBadge, BRow, BCol, BCardText,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect, VueJsonToCsv,
    BButton, ValidationProvider, ValidationObserver, BFormRadioGroup
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      pageLength: 10,
      dir: false,
      selected: 0,
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],
      name: '',
      short_name: null,
      code: '',
      selected_row: {},
      columns: [
        {
          label: 'SL #',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Short Name',
          field: 'short_name',
        },
        {
          label: 'Code',
          field: 'code',
        },
        {
          label: 'Class',
          field: 'classes.name',
        },
        {
          label: 'Optional',
          field: 'is_optional',
        },
        {
          label: 'Student Group',
          field: 'student_group.name',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      columns3: [
        {
          label: 'SL #',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Code',
          field: 'code',
        },
        {
          label: 'Class',
          field: 'classes',
          dataFormat: this.formatData,
        },
        {
          label: 'Version',
          field: 'version',
          dataFormat: this.formatData,
        },
        {
          label: 'Student Group',
          field: 'student_group',
          dataFormat: this.formatData,
        },
        {
          label: 'Status',
          field: 'is_active',
        },
      ],
      searchTerm: '',
      status: {
        1: 'active',
        0: 'deactivate',
      },
      status2: {
        1: 'Yes',
        0: 'No',
      },
      classes_id: null,
      //version_id: null,
      student_group_id: null,
      classes: [],
      //versions: [],
      student_group_list: [],
      columns10: [
        {
          label: 'Name',
          field: 'Name',
        },
        {
          label: 'Code',
          field: 'Code',
        },
        {
          label: 'Class',
          field: 'Classes',
        },
        {
          label: 'Version',
          field: 'Version'
        },
        {
          label: 'Student Group',
          field: 'StudentGroup',
        },
        {
          label: 'Has Part',
          field: 'HasPart',
        },
        {
          label: 'Has Mark Effect',
          field: 'HasMarkEffect',
        },
        {
          label: 'Is Grade Subject',
          field: 'IsGradeSubject',
        },
        {
          label: 'Status',
          field: 'Status',
        },
      ],
    }
  },
  methods: {
    formatData(value) {
      return value.name;
    },
    csvExport() {
      let sub = this.subjects.map(item => ({
        StudentGroup: item.student_group.name,
        version: item.version.name,
        classes: item.classes.name
      }));
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(sub[0]).join(";"),
        ...sub.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    async getGroupList() {
      await apiCall.get('/get/active/student/group').then((response) => {
        this.student_group_list = response.data;
      }).catch(() => {

      })
    },

    async getAll() {
      await apiCall
        .get("get/active/class/with/v/s/groups")
        .then((response) => {
          this.classes = response.data;
        })
        .catch(() => {
          this.classes = [];
          this.versions = [];
          this.student_group_list = [];
        });
    },
    viewDetails(row) {
      this.selected_row = row;
      this.$refs['information-view'].show()
    },
    showModal(row) {
      this.selected_row = row;
      this.selected = row.is_optional;
      this.name = row.name;
      this.code = row.code;
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    async edit() {

      await this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data = new FormData(this.$refs.add_or_update_form);
          data.append('id', this.selected_row.id);
          apiCall.post(`/subject/update${this.selected_row.id}`, data).then((response) => {
            if (response.data.status === 'success') {
              this.$toaster.success(response.data.message);
              this.hideModal();
              this.$store.dispatch('GET_ALL_SUBJECT');
              this.$refs.simpleRules.reset();
            } else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error) => {
            if (error.response.status == 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    async remove(id, index) {
      await this.$swal({
        title: 'Are you sure?',
        text: "You want to remove!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.delete(`/subject/remove${id}`).then((response) => {
            if (response.data.status === 'success') {
              this.$toaster.success(response.data.message);
              this.$store.commit('REMOVE_SUBJECT', index);
            } else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error) => {
            if (error.response.status == 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            } else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    async statusChange(id) {
      await apiCall.put(`/subject/status/change${id}`).then((response) => {
        this.$toaster.success(response.data.message);
        this.$store.dispatch('GET_ALL_SUBJECT');
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
        this.$store.dispatch('GET_ALL_SUBJECT');
      });
    },

    pdfGenerate() {
      /*only change able*/
      let title = "All Subject list";
      let clm = ['Name', 'Code', 'StudentGroup', 'Version', 'Classes', 'HasPart', 'HasMarkEffect', 'IsGradeSubject', 'Status'];
      /*change able end*/
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, { responseType: 'blob' }).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'subject.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-success',
        0: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    ...mapGetters(['student_groups']),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    subjects() {
      let subjectList = this.$store.getters.subjects;
      if (this.classes_id) subjectList = subjectList.filter(item => item.classes_id === this.classes_id);
      //if (this.version_id) subjectList = subjectList.filter(item => item.version_id === this.version_id);
      if (this.student_group_id) subjectList = subjectList.filter(item => item.student_group_id === this.student_group_id);
      if (this.short_name) subjectList = subjectList.filter(item => item.short_name === this.short_name);

      return subjectList;
    },
    getData() {
      return this.subjects.map(item => ({
        Name: item.name,
        Code: item.code,
        StudentGroup: item.student_group ? item.student_group.name : '',
        Version: item.version ? item.version.name : '',
        Classes: item.classes ? item.classes.name : '',
        HasPart: item.has_part ? 'Yes' : 'No',
        HasMarkEffect: item.has_mark_effect ? 'Yes' : 'No',
        IsGradeSubject: item.is_grade_subject ? 'Yes' : 'No',
        Status: item.is_active ? 'Active' : 'Inactive'
      }));
    }
  },
  created() {
    this.$store.dispatch('GET_ALL_SUBJECT');
    if (this.student_group_list.length < 1) {
      this.$store.dispatch("GET_ALL_STUDENT_GROUP");
    }
    this.getAll();
    this.getGroupList();
  },
  watch: {
    /*watcher for class on change*/
    classes_id() {
      let s_classes = this.classes.find((item) => item.id === this.classes_id);
      //this.version_id = null;
      this.student_group_id = null;
      if (s_classes) {
        this.versions = s_classes.versions ?? [];
        this.student_group_list = s_classes.student_groups ?? [];
      } else {
        this.versions = [];
        this.student_group_list = [];
      }
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>